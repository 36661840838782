var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "iq-radio-round",
      attrs: { label: _vm.label, prop: _vm.prop },
    },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "radio-group-filter-round",
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-radio", {
            key: item,
            attrs: { border: "", size: "medium", label: item, value: item },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }