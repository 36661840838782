<template>
  <el-col :span="24">
    <el-form
      ref="formDataNumber"
      :rules="rules"
      :model="formDataNumber"
      class="drivers-slots-form"
      label-position="top"
      @submit.native.prevent
    >
      <div style="display: flex; align-items: end">
        <iq-autocomplete-form
          v-model="formDataNumber.plate_truck"
          size="fullWidth"
          style="margin-bottom: 0px !important; width: 100% !important"
          placeholder="Номер авто полностью с регионом"
          label="Введите номер авто полностью с регионом:"
          prop="plate_truck"
          :options="querySearchAuto"
        />
        <iq-button
          v-if="showSecondStageForm"
          style="margin-left: 8px"
          color="error"
          @onClick="changeNumberAuto"
        >
          <i class="el-icon-edit" />
        </iq-button>
      </div>
      <div style="display: flex; align-items: end; margin-top: 8px">
        <iq-autocomplete-form
          v-model="formDataNumber.plate_trailer"
          size="fullWidth"
          style="margin-bottom: 0px !important; width: 100% !important"
          placeholder="Номер прицепа полностью с регионом"
          label="Введите номер прицепа полностью с регионом:"
          prop="plate_trailer"
          :options="querySearchTrailer"
        />
        <iq-button
          v-if="showSecondStageForm"
          style="margin-left: 8px"
          color="error"
          @onClick="changeNumberAuto"
        >
          <i class="el-icon-edit" />
        </iq-button>
      </div>
      <div style="display: flex; align-items: end">
        <IqRadio
          v-model.number="formDataNumber['truck_spindles_count']"
          :options="autoAxle"
          label="Общее количество осей авто с прицепом:"
        />
      </div>

      <el-form-item v-if="!showSecondStageForm" style="margin-top: 24px">
        <iq-button full-width @onClick="submitForm('formDataNumber')">
          Далее
        </iq-button>
      </el-form-item>
    </el-form>
  </el-col>
</template>

<script>
import {
  DR_FETCH_AUTOCOMLETE_DATA,
  DR_GET_AUTOCOMLETE_DATA,
  DR_SET_FULL_FORM_WITH_AUTO,
} from '@/store/actions'
import { autoAxle } from '@/constants/auto-type'
import { getIsAndroid, numAutoValidator, numTrailerValidator } from '@/core'
import { isArray } from 'lodash'
import { isObject } from 'element-ui/src/utils/types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IqAutocompleteForm from '../../ui/components/autocomplete/IqAutocompleteForm'
import IqButton from '../../ui/components/buttons/IqButton'
import IqRadio from '../../ui/components/radio/IqRadio'
import _ from 'lodash/fp'

export default {
  name: 'TruckForm',
  components: {
    IqAutocompleteForm,
    IqButton,
    IqRadio,
  },
  props: ['formData', 'timeslotsList', 'showSecondStageForm'],
  data() {
    return {
      autoAxle,
      rules: {
        plate_truck: [
          {
            required: true,
            message: 'Заполните номер авто',
            trigger: 'change',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (numAutoValidator(value)) {
                callback(
                  new Error(
                    'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
                  ),
                )
              }
              if (this.validatePass(rule, value)) {
                callback(
                  new Error(
                    'Данный номер используется в предыдущем тайм слоте',
                  ),
                )
              }

              return callback()
            },

            trigger: 'change',
          },
        ],
        plate_trailer: [
          {
            required: true,
            message: 'Заполните номер прицепа',
            trigger: 'change',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (numTrailerValidator(value)) {
                callback(new Error('Формат номера должен быть "CT176976"'))
              }
              if (this.validatePass(rule, value)) {
                callback(
                  new Error(
                    'Данный номер используется в предыдущем тайм слоте',
                  ),
                )
              }

              return callback()
            },

            trigger: 'change',
          },
        ],
      },

      formDataNumber: {
        plate_truck: '',
        plate_trailer: '',
        truck_spindles_count: 2,
      },
      autoFromState: [],
      trailerFromState: [],
    }
  },
  computed: {
    ...mapGetters({
      autocompleteData: DR_GET_AUTOCOMLETE_DATA,
    }),
    isAndroid() {
      return getIsAndroid()
    },
  },
  watch: {
    autocompleteData: {
      immediate: true,
      handler(value) {
        if (isObject(value)) {
          this.autoFromState = {
            value: value.plate_truck,
            link: value.plate_truck,
          }
        }
        if (isArray(value)) {
          let autoFromStateArray = value.map(item => item.plate_truck)

          this.autoFromState = this.unique(autoFromStateArray).map(item => ({
            value: item,
            link: item,
          }))
        }
      },
    },
  },
  mounted() {
    this.fetchAutocompleteData()
  },
  methods: {
    ...mapActions({
      fetchAutocompleteData: DR_FETCH_AUTOCOMLETE_DATA,
    }),

    unique(arr) {
      let result = []

      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str)
        }
      }

      return result
    },

    validatePass(rule, value) {
      const statuses = ['not_stated', 'parked_far', 'parked_close', 'detached']
      let valid = false

      this.formData.map(item => {
        if (
          item.plate_truck === value &&
          this.ruleForm.timeslot_id !== item.timeslot_id
        ) {
          valid = true
        }
      })
      let dublicateSlot = this.timeslotsList.filter(
        item =>
          item.plate_truck === value && statuses.includes(item.truck_status),
      )

      if (dublicateSlot.length > 0) {
        valid = true
      }

      return valid
    },
    ...mapMutations({ updateFormData: DR_SET_FULL_FORM_WITH_AUTO }),
    changeNumberAuto() {
      this.$emit('changeNumber')
    },
    querySearchAuto(queryString, cb) {
      const autoNumbers = this.autoFromState?.filter(
        item => !_.find(this.formData, { plate_truck: item.value }),
      )
      const results = queryString
        ? autoNumbers.filter(this.createFilter(queryString))
        : autoNumbers

      cb(results)
    },
    querySearchTrailer(queryString, cb) {
      const trailerNumbers = this.trailerFromState?.filter(
        item => !_.find(this.formData, { plate_trailer: item.value }),
      )
      const results = queryString
        ? trailerNumbers.filter(this.createFilter(queryString))
        : trailerNumbers

      cb(results)
    },
    querySearchName(queryString, cb) {
      var name = this.nameFromState
      var results = queryString
        ? name.filter(this.createFilter(queryString))
        : name

      cb(results)
    },

    querySearchPhone(queryString, cb) {
      var phones = this.phoneFromState
      var results = queryString
        ? phones.filter(this.createFilter(queryString))
        : phones

      cb(results)
    },
    createFilter(queryString) {
      return link =>
        link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit('continue', this.formDataNumber)
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="sass">
$greyBackColor: #E8E8E8
$blueBackColor: #C1E1EC
$fontColor: #909399
$mainFont: #333333
$greenColor: #008400
$redColor: red

.drivers-slots-form
  background: white
  font-weight: normal
  font-size: $fs-14
  line-height: $lh-16
  position: relative
  .el-form-item
    &__label
      text-align: left
      line-height: 22px
      font-weight: bold
      width: 100%
      padding: 0 !important
  .el-date-editor
    width: 100% !important
  +to(550px)
    .el-form-item__label
      min-width: 300px!important
  .el-form-item__content
    line-height: 32px
  .el-select
    width: 100%
  .el-input__icon:before
    font-size: 14px
#app
  .confirm-form
    .el-form-item
    .el-form-item__label
      width: auto!important
    +to(550px)
      .el-form-item__label
        width: 100% !important
      .el-form-item__content
        margin-left: 0 !important
.drivers-slot-btn
  background: $color-blue-light
  width: 100%
  padding: 12px 0px
  height: 45px
.drivers-main-container
  background: white
  margin-top: 40px
  padding: 15px
  max-height: 80vh
  overflow: scroll
  min-width: 320px
  &.android
    margin-top: 0
    padding: 40px 15px 80px
    max-height: unset
  .el-form-item__label
    width: 100% !important
    &:before
      font-size: 14px
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 14px!important

.el-message.driver-message
  top: 50px!important
  min-width: 90%!important
  margin: 50px
  left: 45%!important
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 28px!important
  +to(768px)
    top: 50px!important
    min-width: 90%!important
    margin: 50px
    left: 43%!important
  +to(390px)
    top: 50px!important
    min-width: 90%!important
    margin: 50px
    duration: 30000!important
    left: 37%!important
.confirm-add-auto
  .el-autocomplete
    width: 100%
  .el-select
    width: 100%
  .auto-number-confirm
    display: flex
    justify-content: space-between
    align-items: center
  .drivers-slots-form
    background: white
    font-weight: normal
    font-size: $fs-14
    line-height: $lh-16
    position: relative
    .el-form-item
      &__label
        text-align: left
        line-height: 22px
        font-weight: bold
        width: 100%
        padding: 0 !important
    .el-date-editor
      width: 100% !important
    +to(550px)
      .el-form-item__label
        min-width: 300px!important
    .el-form-item__content
      line-height: 32px
    .el-select
      width: 100%
    .el-input__icon:before
      font-size: 14px
  .line
    display: flex
    justify-content: center
    align-items: center
i
  @media only screen and (max-width: 550px)
    &:before
      font-size: 14px!important
.no-quota
  display: block
  margin-top: 12px
  padding: 8px
  text-align: center
  background: rgba(239, 0, 9, 0.29)
  color: white
  border-radius: 4px
  margin-bottom: 24px
.input-quota
  margin-bottom: 10px
  border-radius: 4px
.confirm-modal
  width: 400px!important
  padding: 8px 8px 24px 8px
  @media only screen and (max-width: 550px)
    width: 50%
  label
    font-size: 13px
  .el-message-box__message
    font-size: 16px
  .confirmDelete
    background: $btn-orange
    border-color: $btn-orange
  .el-button
    font-size: 14px
.confirm-title
  text-align: center
  margin: 8px
  margin-bottom: 16px
.timer-slots
  margin-bottom: 16px
  display: flex
  justify-content: space-between
  align-items: center
  > span
    display: block
    color: $btn-orange
    font-weight: bold
</style>
